(<template>
  <article class="prof-pers-info__wrapper js-for-phone-verification">
    <div class="prof-pers-info__table">
      <h3 class="prof-pers-info__table-title">{{ $gettext('Information') }}</h3>
      <div :class="{'prof-pers-info__table-row--phone': userIsInterpreter}"
           class="prof-pers-info__table-row">
        <div class="prof-pers-info__table-cell prof-pers-info__table-cell--title">{{ $gettext("Telephone:") }}</div>
        <template v-if="phone">
          <div class="prof-pers-info__table-phone-cell">
            <span>{{ phone }}</span>
          </div>
          <div class="prof-pers-info__table-cell">
            <button
              class="edit-pencil-btn--outlined prof-pers-info__edit-phone-btn"
              @click="openPhoneVerificationModal"></button>
          </div>
        </template>
        <div v-else
             class="prof-pers-info__table-cell">
          <span>
            <button class="profile__add-info-btn"
                    @click="openEditModal">{{ $gettext("Add your phone number") }}</button>
          </span>
        </div>

      </div>
    </div>
    <hr class="prof-pers-info__divider" />
    <div v-if="userIsNotInterpreter && canEdit && !phoneVerified"
         class="prof-pers-info__table-row">
      <div class="prof-pers-info__table-cell prof-pers-info__table-cell--title"></div>
      <div class="prof-pers-info__table-cell">
        <button type="button"
                class="sk-btn sk-btn--white prof-pers-info__verify-phone-btn"
                @click="openPhoneVerificationModal">{{ $gettext('Verify mobile number') }}</button>
      </div>
    </div>
    <div v-if="$isGodmode()"
         :class="{'prof-pers-info__table-row--phone': userIsInterpreter}"
         class="prof-pers-info__table-row">
      <div class="prof-pers-info__table-cell prof-pers-info__table-cell--title">{{ $gettext("Tel(additional):") }}</div>
      <div class="prof-pers-info__table-cell">
        <template v-if="alternativePhone">
          <span>{{ alternativePhone }}</span>
          <button v-if="userIsInterpreter"
                  class="edit-pencil-btn prof-pers-info__edit-phone-btn"
                  @click="openPhoneVerificationModal"></button>
        </template>
        <span v-else>
          <button class="profile__add-info-btn"
                  @click="openEditModal">{{ $gettext("Add additional phone number") }}</button>
        </span>
      </div>
    </div>
    <div v-if="userIsNotInterpreter && canEdit && !alternativePhoneVerified && $isGodmode()"
         class="prof-pers-info__table-row">
      <div class="prof-pers-info__table-cell prof-pers-info__table-cell--title"></div>
      <div class="prof-pers-info__table-cell">
        <button type="button"
                class="sk-btn sk-btn--white prof-pers-info__verify-phone-btn"
                @click="openAdditionalPhoneVerificationModal">{{ $gettext('Verify additional mobile number') }}</button>
      </div>
    </div>
    <div class="prof-pers-info__table-row--container">
      <div class="prof-pers-info__table-row--left">
        <div class="prof-pers-info__table-row">
          <div class="prof-pers-info__table-cell prof-pers-info__table-cell--title">{{ $gettext("Email:") }}</div>
          <div class="prof-pers-info__table-cell">
            <span v-if="email">{{ email }}</span>
            <span v-else>
              <button class="profile__add-info-btn"
                      @click="openEditModal">{{ $gettext("Add email") }}</button>
            </span>
          </div>
        </div>
        <template v-if="userIsInterpreter">
          <div class="prof-pers-info__table-row">
            <div class="prof-pers-info__table-cell prof-pers-info__table-cell--title">{{ $gettext("Gender:") }}</div>
            <div class="prof-pers-info__table-cell">
              <span v-if="computedGender">{{ computedGender }}</span>
              <span v-else>
                <button class="profile__add-info-btn"
                        @click="openEditModal">{{ $gettext("Add your gender") }}</button>
              </span>
            </div>
          </div>
          <div class="prof-pers-info__table-row">
            <div class="prof-pers-info__table-cell prof-pers-info__table-cell--title">{{ $gettext("Date of birth:") }}</div>
            <div class="prof-pers-info__table-cell">
              <span v-if="dob">{{ dob }}</span>
              <span v-else>
                <button class="profile__add-info-btn"
                        @click="openEditModal">{{ $gettext("Add your date of birth") }}</button>
              </span>
            </div>
          </div>
        </template>
        <!-- <div class="prof-pers-info__table-row" v-if="userIsInterpreter">
        <div class="prof-pers-info__table-cell prof-pers-info__table-cell--title">{{ $gettext("Date of birth:") }}</div>
        <div class="prof-pers-info__table-cell">
          <span v-if="dob">{{ dob }}</span>
          <span v-else>
            <button class="profile__add-info-btn" @click="openEditModal">{{ $gettext("Add your age") }}</button>
          </span>
        </div>
      </div> -->
        <div v-if="userIsInterpreter"
             class="prof-pers-info__table-row">
          <div class="prof-pers-info__table-cell prof-pers-info__table-cell--title">{{ $gettext("Address:") }}</div>
          <div class="prof-pers-info__table-cell">
            <div v-if="isAddress">
              <p v-if="addressOne">{{ addressOne }}</p>
              <p v-if="addressTwo">{{ addressTwo }}</p>
              <p v-if="county">{{ county }}</p>
              <p v-if="postcode || city">{{ postcode }} {{ city }}</p>
              <p v-if="country">{{ country }}</p>
            </div>
            <div v-else>
              <button class="profile__add-info-btn"
                      @click="openEditModal">{{ $gettext("Add your address") }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="prof-pers-info__table-row--right">
        <button class="edit-pencil-btn--outlined prof-pers-info__edit-btn"
                @click="openEditModal"></button>
      </div>
    </div>
  </article>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter', 'userIsNotInterpreter', 'userIsManager']),
      ...mapGetters('EditProfileStore', ['getPersonInfoModalData']),
      ...mapState('EditProfileStore', {
        profilePerson: (state) => {
          return state.profileData && state.profileData.person ? state.profileData.person : {};
        }
      }),
      paramsList() { return this.params || {}; },
      email() { return this.paramsList.email; },
      dob() { return this.paramsList.dob; },
      gender() { return this.paramsList.gender; },
      addressOne() { return this.paramsList.addressOne; },
      addressTwo() { return this.paramsList.addressTwo; },
      county() { return this.paramsList.county; },
      postcode() { return this.paramsList.postcode; },
      city() { return this.paramsList.city; },
      country() { return this.paramsList.country; },
      phone() { return this.paramsList.phone; },
      alternativePhone() { return this.paramsList.alternativePhone; },
      phoneVerified() { return this.paramsList.phoneVerified; },
      alternativePhoneVerified() { return this.paramsList.alternativePhoneVerified; },
      phoneCode() { return this.profilePerson.phone ? this.profilePerson.phone.code : ''; },
      alternativePhoneCode() { return this.profilePerson.alternativePhone ? this.profilePerson.alternativePhone.code : ''; },
      phoneNumber() { return this.profilePerson.phone ? this.profilePerson.phone.number : ''; },
      alternativePhoneNumber() { return this.profilePerson.alternativePhone ? this.profilePerson.alternativePhone.number : ''; },
      canEdit() { return this.paramsList.canEdit; },
      isAddress() {
        return !!(this.addressOne || this.addressTwo || this.country || this.county || this.postcode || this.city);
      },
      computedGender() {
        if (this.$helperData.getSelectGenders(this) && this.$helperData.getSelectGenders(this).length) {
          for (const g of this.$helperData.getSelectGenders(this)) {
            if (this.gender == g.id) {
              return g.name;
            }
          }
        }
      }
    },
    methods: {
      makePersonInfoModalData() {
        return {
          title: this.$gettext('Edit your personal info'),
          context: this,
          data: this.getPersonInfoModalData
        };
      },
      openEditModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'person-info',
          data: this.makePersonInfoModalData()
        });
      },
      openActiveJobConfirm() {
        this.$store.commit('ModalStore/setModal', {
          component: 'active-job-confirm',
          data: {
            title: this.$gettext('You have active assignments'),
            context: this
          }
        });
      },
      openAdditionalPhoneVerificationModal() {
        this.$store.dispatch('checkActiveJobs').then((data) => {
          if (data.canEditPhone) {
            this.openAdditionalPhoneModal();
          } else {
            this.openActiveJobConfirm();
          }
        });
      },
      openPhoneVerificationModal() {
        // active jobs check for interpreters and customers
        if (!this.userIsManager) {
          this.$store.dispatch('checkActiveJobs').then((data) => {
            if (data.canEditPhone) {
              this.openPhoneModal();
            } else {
              this.openActiveJobConfirm();
            }
          });
        } else {
          this.openPhoneModal();
        }
      },
      openAdditionalPhoneModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'verify-phone',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Verify your phone number'),
            context: this,
            data: {
              isAlternative: true,
              phoneCode: this.alternativePhoneCode,
              phoneNumber: this.alternativePhoneNumber
            }
          }
        });
      },
      openPhoneModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'verify-phone',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Verify your phone number'),
            context: this,
            data: {
              phoneCode: this.phoneCode,
              phoneNumber: this.phoneNumber
            }
          }
        });
      }
    }
  };
</script>

<style scoped>
.prof-pers-info__wrapper {
  display: block;
  align-self: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 10%);
}

.prof-pers-info__table {
  font-weight: normal;
  font-size: 14px;
}

.prof-pers-info__table-row--container {
  display: flex;
  flex-direction: row;
}

.prof-pers-info__table-row--left {
  width: 100%;
}

.prof-pers-info__table-row {
  display: table-row;
}

.prof-pers-info__table-title {
  margin-bottom: 10px;
}

.prof-pers-info__table-row--phone .prof-pers-info__table-cell {
  position: relative;
  display: flex;
}

.prof-pers-info__table-row--phone {
  display: flex;
}

.prof-pers-info__divider {
  margin: 15px 0;
  background-color: #000;
  opacity: 0.2;
}

.prof-pers-info__table-cell {
  display: table-cell;
  vertical-align: middle;
  padding-right: 35px;
  font-size: 14px;
  word-break: break-word;
}

.prof-pers-info__table-phone-cell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  font-size: 14px;
  word-break: break-word;
}

.prof-pers-info__table-cell--title {
  vertical-align: top;
  padding-top: 2px;
  padding-right: 20px;
  font-size: 13px;
  text-align: start;
  word-break: initial;
}

.prof-pers-info__edit-phone-btn {
  position: absolute;
  right: 2%;
  margin-top: -15px;
}

.prof-pers-info__verify-phone-btn {
  display: inline-block;
  width: auto;
  height: 35px;
  margin: 5px 0;
  padding: 0 10px;
  line-height: 33px;
}

</style>
