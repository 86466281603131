(<template>
  <div :class="{'profile__whole-page-cont--business-view': !canEdit}"
       class="profile">
    <div class="profile__page-title">
      <router-link :to="showProfileLink"
                   class="sk-link profile__page-title-link">{{ previewLinkText }}</router-link>
    </div>
    <section class="profile__shadowed-section">
      <avatar-name :params="avatarNameParams"
                   class="profile__name-avatar-section" />
      <personal-info :params="personalInfoParams"
                     class="profile__pers-info-section" />
    </section>
    <section class="profile__main-content-section">
      <template v-if="userIsInterpreter">
        <profile-data class="profile__main-content-section--left"
                      :components-list="leftSectionComponents" />
        <profile-data class="profile__main-content-section--right"
                      :components-list="rightSectionComponents" />
      </template>
      <profile-data v-else
                    class="profile__main-content-section--left"
                    :components-list="commonComponents" />
    </section>
  </div>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';
  import AvatarName from '@/components/profile_components/show/AvatarName';
  import PersonalInfo from '@/components/profile_components/show/PersonalInfo';
  import ProfilePrototype from '@/pages/shared_pages/profile/sh_ProfilePrototype';
  import ProfileData from '@/components/profile_components/ProfileData';

  export default {
    asyncData({store, route}) {
      const storeData = store.state.EditProfileStore.profileData;
      const storeUid = storeData.person
        ? storeData.person.uid
        : storeData.uid || '';
      const id = route.params.id;
      const enterpriseId = store.getters['UserInfoStore/enterpriseId'];
      const userIsAdminOfEnterprise = store.getters['UserInfoStore/userIsAdminOfEnterprise'];
      const userIsNotInterpreter = store.getters['UserInfoStore/userIsNotInterpreter'];
      const page = +route.query.page || 1;
      const items = +route.query.items || 5;
      let promisesArr = [];

      if (!store.state.EnterpriseStore.currentEmployee && userIsNotInterpreter && enterpriseId) {
        promisesArr = [...promisesArr, store.dispatch('EnterpriseStore/getCurrentEmployee')];
      }
      if (!storeData || storeUid != id) {
        promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getUserProfile', {id})];
      }
      if (!store.state.EditProfileStore.settings && store.getters['UserInfoStore/userIsInterpreter']) {
        promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getSettings')];
      }
      if (userIsAdminOfEnterprise && userIsNotInterpreter && !store.state.TTEnterpriseInfoStore.companyInfo) {
        promisesArr = [...promisesArr, store.dispatch('TTEnterpriseInfoStore/getEnterprise', enterpriseId)];
      }
      if (id && store.getters['UserInfoStore/userIsSeller']) {
        promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getTools', id)];
        promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getExperiences', id)];
      }
      if (id && store.getters['UserInfoStore/userIsInterpreter']) {
        promisesArr = [...promisesArr, store.dispatch('EditProfileStore/getInterpreterFeedback', {id, params: {page, items}})];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'avatar-name': AvatarName,
      'personal-info': PersonalInfo,
      'profile-data': ProfileData
    },
    extends: ProfilePrototype,
    data() {
      return {
        canEdit: true,
        showPercentage: true
      };
    },
    computed: {
      ...mapGetters('EditProfileStore', [
        'getPersonInfoModalData'
      ]),
      ...mapGetters('UserInfoStore', ['userUid', 'maxShortNoticeCancellation', 'userIsManager']),
      ...mapState('TTEnterpriseInfoStore', {
        enterpriseOwnerUid: (state) => state.companyInfo?.owner_uid || ''
      }),
      commonComponents() {
        return [{name: 'delete-account-btn', condition: true}].filter((component) => component.condition === true);
      },
      leftSectionComponents() {
        const components = [
          {name: 'about-info', params: {aboutText: this.about, canEdit: this.canEdit}, condition: true},
          {name: 'tools', params: {tools: this.tools, canEdit: this.canEdit}, condition: this.isTranslator},
          {name: 'schools', params: {schools: this.education, canEdit: this.canEdit}, condition: true},
          {name: 'courses', params: {courses: this.courses, canEdit: this.canEdit}, condition: true},
          {name: 'methods', params: {methods: this.interpretationMethods, canEdit: this.canEdit}, condition: true},
          {name: 'int-types', params: {interpretationTypes: this.intTypes, canEdit: this.canEdit}, condition: true},
          {name: 'methods', params: {methods: this.translationMethods, canEdit: this.canEdit, isTranslation: true}, condition: this.isTranslator},
          {name: 'experiences', params: {experiences: this.experiences, canEdit: this.canEdit}, condition: this.isTranslator},
          // TODO: Refactor and remove not used anymore
          // {name: 'int-hours', params: {intHours: this.intHours, canEdit: this.canEdit}},
          {name: 'public-profile', condition: this.userIsInterpreter},
          {name: 'delete-account-btn', condition: this.userIsInterpreter}
          // {name: 'auth-providers', params: {socialConnections: this.authProviders, canEdit: this.canEdit}},
          // {name: 'referral', params: {referral: this.referralInfo, canEdit: this.canEdit}}
        ];
        return components.filter((component) => component.condition === true);
      },
      rightSectionComponents() {
        return [
          {name: 'skills', condition: true, params: {allSkills: this.skills, canEdit: this.canEdit}},
          {name: 'badges', condition: true, params: {badges: this.badges}},
          {name: 'approved-interpreting-skills',
           condition: this.isAnyInterpretationSkills,
           params: {badges: this.badges}},
          {
            name: 'reviews',
            condition: true,
            params: {
              publicFeedbacks: this.feedbacks,
              stats: this.stats,
              canEdit: this.canEdit,
              totalPages: this.totalFeedbacks
            }
          }
        ].filter((component) => component.condition === true);
      },
      avatarNameParams() {
        const params = {
          avatar: this.avatar,
          firstName: this.firstName,
          lastName: this.lastName,
          canEdit: this.canEdit,
          showPercentage: this.showPercentage,
        };
        if (this.userIsInterpreter) {
          params.tolkId = this.tolkId;
          params.gender = this.gender;
          params.stats = this.stats;
          params.city = this.city;
          params.country = this.country;
          params.blockedByStatus = this.isBlockedByStatusInterpreter;
          params.profileData = {
            ...this.profileData,
            id: this.profilePerson.id || ''
          };
        }
        return params;
      },
      personalInfoParams() {
        const params = {
          email: this.userEmail,
          phone: this.fullPhone,
          alternativePhone: this.alternativeFullPhone,
          phoneVerified: this.phoneVerified,
          alternativePhoneVerified: this.alternativePhoneVerified,
          canEdit: this.canEdit
        };
        if (this.userIsInterpreter) {
          params.dob = this.dateOfBirth;
          params.gender = this.gender;
          params.addressOne = this.addressLine1;
          params.addressTwo = this.addressLine2;
          params.city = this.city;
          params.country = this.country;
          params.county = this.addressCounty;
          params.postcode = this.addressPostcode;
        }
        return params;
      },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; },
      showPercents() { return this.userIsInterpreter && this.percents && this.percents < 100; },
      previewLinkText() {
        if (!this.userIsManager) return this.userIsInterpreter ? this.$gettext('View as customer') : this.$gettext('View profile');
      },
      showProfileLink() {
        if (this.userIsManager) return this.getEditProfileLink('ManagerUserProfile');
        return this.userIsNotInterpreter ? this.getEditProfileLink('BuyerUserProfile') : this.getEditProfileLink('ServerProfile');
      },
      phoneVerified() {
        if (this.profilePerson.phone) {
          if (this.phoneCode && this.phoneNumber) {
            return this.profilePerson.phone.verified;
          }
        }
        return true;
      },
      userIsOwnerOfEnterprise() { return this.userUid == this.enterpriseOwnerUid; }
    },
    methods: {
      deleteAccount() {
        if (this.userIsOwnerOfEnterprise) {
          this.$store.commit('ModalStore/setModal', {
            component: 'delete-enterprise-admin-account-modal',
            width: 650,
          });
        } else {
          this.$store.commit('ModalStore/setModal', {
            component: 'delete-account-modal',
            width: 600,
            data: {
              title: this.$gettext('Delete my account'),
              context: this,
              maxShortNoticeCancellation: this.maxShortNoticeCancellation
            }
          });
        }
      },
      alternativePhoneVerified() {
        if (this.profilePerson.alternativePhone) {
          if (this.alternativePhoneCode && this.alternativePhoneNumber) {
            return this.profilePerson.alternativePhone.verified;
          }
        }
        return true;
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('EditProfileStore/removeUserProfile');
      this.$store.commit('EditProfileStore/removeTools');
      this.$store.commit('EditProfileStore/removeExperiences');
      next();
    }
  };
</script>

<style src="@assets/css/profile_pages.css"></style>

<style scoped>
.profile__page-title {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  font-size: 18px;
  text-align: center;
}

.profile__whole-page-cont--business-view .profile__main-content-section {
  margin-top: 70px;
}

.profile__shadowed-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
}

.profile__public-profile-main-cont .profile__shadowed-section {
  justify-content: center;
}

.profile__name-avatar-section,
.profile__pers-info-section {
  flex-grow: 1;
  width: 33%;
  margin-top: 10px;
}

.profile__name-avatar-section {
  align-items: start;
  justify-content: space-around;
  width: 63%;
  min-width: 400px;
  margin-right: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 10%);
}

.profile__public-profile-main-cont .profile__name-avatar-section {
  max-width: 750px;
}

.profile__percentage-section {
  flex-grow: 0;
  flex-shrink: 0;
  width: 300px;
}

.profile__percentage-section--mobile {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 0;
}

.profile__calendar-public-wrapper {
  display: block;
  flex-shrink: 0;
  flex-basis: 340px;
}

@media (max-width: 767px) {
  .profile__shadowed-section {
    margin: 0 15px;
  }

  .profile__page-title {
    min-height: 35px;
  }

  .profile__page-title-link {
    position: static;
    font-weight: bold;
    transform: translateY(0);
  }

  .profile__name-avatar-section,
  .profile__pers-info-section {
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }

  .profile__name-avatar-section {
    min-width: auto;
    margin-top: 15px;
  }

  .profile__calendar-public-wrapper {
    width: 100%;
  }

  .profile__percentage-section--desktop {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 0;
    height: 0;
  }

  .profile__percentage-section--mobile {
    position: static;
    overflow: visible;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 20px 10px;
    background-color: #ccf6e4;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  body.is-opened-direct-calendar .profile__main-content-section {
    margin-top: 0;
    padding: 0;
  }

  body.is-opened-direct-calendar .profile__additional-info-wrapper {
    display: none;
  }

  .profile__additional-info-wrapper {
    flex-basis: auto;
    padding-right: 0;
  }
}

@media (min-width: 767px) and (max-width: 1015px) {
  .profile__name-avatar-section,
  .profile__pers-info-section {
    margin: 10px 15px;
  }
}

</style>
